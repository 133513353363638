<template>
    <b-container class="bv-example-row">
        <b-row>
            <b-col>
                <b-alert show variant="light" class="alert alert-custom alert-white alert-shadow fade show gutter-b">
                    <div class="alert-icon">
                        <span class="svg-icon svg-icon-lg">
                            <inline-svg src="media/svg/icons/Tools/Compass.svg" />
                        </span>
                    </div>
                    <div class="alert-text">
                        You are seeing a sensor without internet connection, this device might look offline sometimes due to the sync coverange in the area
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <SuitchBasicChart :device="current_device" :dataset="markers" prop="line-chart" :labelOfdata="labelOfData" />
            </b-col>
            
        </b-row>
        <b-row>
            <b-col>
                <SuitchBatteryWidget :device="current_device" :limitBatteryDate="limitBatteryDate"/>
                <br>
                <SuitchDataDetailsWidget :device="current_device" :dataset="markers"/>

            </b-col>
            <b-col>
                <SuitchTrackerStatusWidget :device="current_device" findMyType="soil"/>
                <br />
                
                <br />
                <SuitchDetailsWidget :device="current_device" />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="6"> </b-col>
            <b-col cols="4">
                <!-- more information here -->
            </b-col>
        </b-row>
    </b-container>

    <!--end::Example-->
</template>

<script>
import {
    FETCH_SINGLE_DEVICE,
    GET_FINDMY_SOIL_SENSOR,
} from "@/core/services/store/devices.module";
import SuitchTrackerStatusWidget from "@/view/content/widgets/suitch/suitch-tracker-status-widget.vue";
import SuitchBatteryWidget from "@/view/content/widgets/suitch/suitch-battery-widget.vue";
import SuitchDataDetailsWidget from "@/view/content/widgets/suitch/suitch-raw-details-widget.vue";
import SuitchDetailsWidget from "@/view/content/widgets/suitch/suitch-details-widget.vue";
import SuitchBasicChart from "@/view/content/widgets/suitch/suitch-chart-generic-widget.vue";


export default {
    data() {
        return {
            limitBatteryDate:"",
            global_wizard: {},
            current_device: null,
            markers: [],
            labelOfData: ''
        };
    },

    computed: {
    },

    components: {
        SuitchTrackerStatusWidget,
        SuitchDetailsWidget,
        SuitchDataDetailsWidget,
        SuitchBasicChart,
        SuitchBatteryWidget,
    },

    methods: {


    },
    mounted() {
        // console.log("route:",this.$route.params);
        const device_token = this.$route.params.token;

        this.$store
            .dispatch(FETCH_SINGLE_DEVICE, { device_token })
            // go to which page after successfully login
            .then((data) => {
                // download devices list
                this.current_device = data;
                if(this.current_device.version == 'templessv1'){
                    this.labelOfData = 'Temperature in °C';
                } else if(this.current_device.version == 'soilblessv1'){
                    this.labelOfData = 'Moisture';
                }
            });
        this.$store
            .dispatch(GET_FINDMY_SOIL_SENSOR, { device_token: device_token })
            // go to which page after successfully login
            .then((data) => {
                // data is an array of objects with the next shape {value:base64, created_at: date}
                // decode the base64 of value field of each object and store in a local object
                const imarkers = data.map((item) => {
                    return {
                        value: item.value,
                        created_at: item.created_at,
                        showTooltip: false,
                    };
                });
                if(this.markers.length == 0){
                    this.limitBatteryDate = imarkers[0].created_at;
                }
                this.markers = imarkers;
            });
    },
};
</script>

<template>
    <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <h6 class="mb-0">Device battery details</h6>
            </div>
        </template>
        <div>
            <div class="form-group">
                <label>Set a new battery:</label>
                <select name="select-action" class="form-control form-control-solid form-control-lg"
                    aria-describedby="enter-act-feedback" v-model="selected_battery">
                    <option :value="-1" class="d-flex justify-content-between align-items-center" disabled>
                        Select an action
                    </option>
                    <option :value="obj.id" v-for="(obj, i) in batteries_available" :key="i"
                        class="d-flex justify-content-between align-items-center">
                        {{ obj.name }}</option>
                </select>
                <b-form-invalid-feedback id="enter-act-feedback">
                    Select a battery if you changed it
                </b-form-invalid-feedback>
                <span class="form-text text-muted">Select a battery if you changed it</span>
            </div>
            <b-button variant="success" v-on:click="resetBattery" :disabled="btnUpdate == true">
                <b-spinner small v-show="btnUpdate == true"></b-spinner>
                Update
            </b-button>
            <br />
            <br />
            <!-- <b-card no-body class="overflow-hidden" style="max-width: 350px;"> -->
            <b-row no-gutters>
                <b-col md="4">
                    <b-card-img :src="imageSrc" class="rounded-0"></b-card-img>
                </b-col>
                <!-- <b-col md="6">
                        <b-card-body :title="battery_summary">
                            <b-card-text>
                                Remaining {{ remainingDays }} days
                                <br />
                                Installed on {{ startDate }}
                                <br />
                                Replace by {{ endDate }}
                            </b-card-text>
                        </b-card-body>
                    </b-col> -->
                <b-col>
                    <b-row no-gutters>
                        <b-col sm="5" style="margin: 2px;">
                            <b-card bg-variant="secondary" text-variant="black" class="text-center">
                                <b-icon class="h1" icon="lightning-charge-fill" variant="Default" v-b-tooltip.hover
                                    title="Remaing battery"></b-icon>
                                <b-card-text style="font-size: 11px;">{{ battery_summary }} remaining<br />.<br />.</b-card-text>
                            </b-card>
                        </b-col>
                        <b-col sm="5" style="margin: 2px;">
                            <b-card bg-variant="secondary" text-variant="black" class="text-center">
                                <b-icon class="h1" icon="hourglass-split" variant="Default" v-b-tooltip.hover
                                    title="Remaining days and total days running"></b-icon>
                                <b-card-text style="font-size: 11px;">
                                    {{ remainingDays }}d left<br />
                                    {{ runningDays }}d running<br/>
                                    {{  extraLabel }}
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col sm="5" style="margin: 2px;">
                            <b-card bg-variant="secondary" text-variant="black" class="text-center">
                                <b-icon class="h2" icon="calendar3" variant="Default" v-b-tooltip.hover
                                    title="Start date of battery replacement"></b-icon>

                                <b-card-text>
                                    Installed on {{ startDate }}
                                </b-card-text>
                            </b-card>
                        </b-col>
                        <b-col sm="5" style="margin: 2px;">
                            <b-card bg-variant="secondary" text-variant="black" class="text-center">
                                <b-icon class="h2" icon="reply-all-fill" variant="Default" v-b-tooltip.hover
                                    title="Replace the battery by this date"></b-icon>

                                <b-card-text>
                                    Replace by {{ endDate }}
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <!-- </b-card> -->
        </div>
        <br />
        <template v-if="statusLoaded == null">
            <div class="d-flex justify-content-center mb-3">
                <b-spinner variant="primary"> </b-spinner>
            </div>
        </template>
        <template v-else>
            <div>
                <b-button v-b-toggle.collapse-1b variant="primary">Show battery history</b-button>
                <b-collapse id="collapse-1b" class="mt-2">
                    <b-card>
                        <div>
                            <b-table striped hover :items="markers" @row-clicked="rowClicked"
                                :tbody-tr-class="rowClassCallback" :fields="tableFields">
                                <template v-slot:cell(created_at)="data">
                                    {{ formatDate(data.item.created_at) }}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </b-collapse>
            </div>
        </template>
    </b-card>
</template>

<script>
import { STORE_TRACKER_LOCATIONS } from "@/core/services/store/devices.module";
import {
    GET_BATTERY_DETAILS,
    GET_BATTERIES,
    POST_BATTERY_RESET,
} from "@/core/services/store/devices.module";
import { mapGetters } from "vuex";
export default {
    name: "suitch-battery-widget",
    components: {},
    props: {
        findMyType: {
            type: String,
            default: "",
        },
        device: null,
        limitBatteryDate: {
            type: String,
            default: "",
        },
        showName: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "setMessage") {
                // verify if the type of message is for this widget
                if (
                    this.device != null &&
                    state.realtime.last_message.type == "location" &&
                    state.realtime.last_message.token == this.device.token
                ) {
                    // call again the action to get the status
                    //this.downloadData();
                }
            } else if (mutation.type == "storeBatteryInfo") {
                console.log("storeBatteryInfo");
                this.calculateRunningDates();
            } else if (mutation.type === STORE_TRACKER_LOCATIONS && this.findMyType == "tracker") {
                state;
                // console.log("TRACKER_battery", this.$store.state.devices.trackerLocations, this.current_device);
                if (this.$store.state.devices.trackerLocations.length > 0) {
                    this.limitBatteryDate = this.$store.state.devices.trackerLocations[0].created_at;
                    console.log(".....last time viewed", this.limitBatteryDate);
                }
                this.calculateRunningDates();
                this.statusLoaded = true;
            }
        });
        //////

        ////
        this.downloadData();
    },
    data() {
        return {
            imageSrc: 'media/devices/batteries/cr2477.png',
            current_device: { connection_type: -1 },
            statusLoaded: null,
            markers: [],
            markersLoaded: false,
            tableFields: [
                { key: "battery_type_name", label: "Type" },
                { key: "created_at", label: "Updated At", formatter: "formatDate" },
            ],
            btnUpdate: false,
            remainingDays: 0,
            runningDays: 0,
            extraDays: 0,
            extraLabel:".",
            startDate: "",
            endDate: "",
            battery_summary: "",
            batteries_available: [],
            selected_battery: -1,
            battery_cycle_date: "",
        };
    },
    methods: {
        rowClassCallback(item, type) {
            if (type === "row") {
                return this.isSelected(item) ? "map-selected-row" : "";
            }
            return "";
        },
        rowClicked(clickedItem) {
            if (this.selectedMarker === clickedItem) {
                this.selectedMarker = null; // Unselect if it's already selected
            } else {
                this.selectedMarker = clickedItem;
            }
        },
        formatDate(dateString) {
            const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                // hour: "2-digit",
                // minute: "2-digit",
            };
            let date;
            try {
                date = new Date(dateString);
                if (isNaN(date)) {
                    throw new Error("Invalid date");
                }
                return new Intl.DateTimeFormat(undefined, options).format(date);
            } catch (err) {
                console.error("Error formatting date:", err);
                return null;
            }
        },
        resetBattery() {
            this.btnUpdate = true;
            this.$store
                .dispatch(POST_BATTERY_RESET, {
                    token: this.device.token,
                    selectedBattery: this.selected_battery,
                })
                // go to which page after successfully login
                .then((data) => {
                    // download devices list
                    // console.log(data);
                    data;
                    this.btnUpdate = false;
                });
        },
        updateBatteryInfo() {
            if (this.device != null) {
                // verify if we have batteries
                // const all_batteries = this.$store.state.devices.battery_info;
                // console.log("all_batteries", all_batteries);

                this.$store
                    .dispatch(GET_BATTERY_DETAILS, { device_token: this.device.token })
                    // go to which page after successfully login
                    .then((data) => {
                        // download devices list
                        this.markers = data;
                        let highest = this.markers.reduce(
                            (acc, current) => {
                                return acc.battery_cycle_id > current.battery_cycle_id
                                    ? acc
                                    : current;
                            },
                            { battery_cycle_id: -Infinity }
                        ); // Start with an object that has the lowest possible battery_cycle_id
                        this.startDate = this.formatDate(highest.created_at);
                        //find the current battery
                        const actual_battery = this.$store.state.devices.battery_info.filter(
                            (item) => {
                                // console.log(item.id, highest.battery_id);
                                return item.id == highest.battery_id;
                            }
                        );
                        if (actual_battery.length == 0) {
                            return;
                        }
                        // console.log("actual_battery", actual_battery);
                        // change the image of the battery
                        if (actual_battery[0].name == "C2") {
                            this.imageSrc = 'media/devices/batteries/cr17345.png';
                        } else if (actual_battery[0].name == "CR2477") {
                            this.imageSrc = 'media/devices/batteries/cr2477.png';
                        } else if (actual_battery[0].name == "CR2032" || actual_battery[0].name == "CR232" || actual_battery[0].name == "CR2032 Steren" || actual_battery[0].name == "CR2032 Energizer") {
                            this.imageSrc = 'media/devices/batteries/cr2032.png';
                        } else if (actual_battery[0].name == "CR2450") {
                            this.imageSrc = 'media/devices/batteries/cr2477.png';
                        } else if (actual_battery[0].name == "18650") {
                            this.imageSrc = 'media/devices/batteries/18650.png';
                        } else if (actual_battery[0].name == "CR2450H") {
                            this.imageSrc = 'media/devices/batteries/cr2450h.png';
                        } else if (actual_battery[0].name == "CR2477N") {
                            this.imageSrc = 'media/devices/batteries/cr2477n.png';
                        } else if (actual_battery[0].name == "CR2477H") {
                            this.imageSrc = 'media/devices/batteries/cr2477h.png';
                        }
                        let dateObj = new Date(highest.created_at); // Convert to Date object
                        dateObj.setDate(dateObj.getDate() + actual_battery[0].days); // add num days
                        this.endDate = this.formatDate(dateObj);
                        let currentDate = new Date(); // Current date
                        // Calculate the difference in milliseconds
                        let differenceInMilliseconds = dateObj - currentDate;
                        // Convert milliseconds to days (1000 milliseconds in a second, 60 seconds in a minute, 60 minutes in an hour, 24 hours in a day)
                        this.remainingDays =
                            differenceInMilliseconds / (1000 * 60 * 60 * 24);
                        // Round down to the nearest whole number
                        this.remainingDays = Math.floor(this.remainingDays);
                        if (this.remainingDays < 0) {
                            this.remainingDays = 0;
                        }
                        const porcentage = Math.floor(
                            (100 * this.remainingDays) / actual_battery[0].days
                        );
                        // calculate the total days running since the battery was installed
                        this.battery_cycle_date = highest.created_at;
                        this.calculateRunningDates();
                        this.battery_summary = porcentage + "%";
                        //now find the
                        // console.log("latest_battery_reset=>", highest);
                        // console.log("current_battery_info=>", this.battery_summary);
                    });
            }
            this.statusLoaded = {};
            this.btnUpdate = false;
        },
        calculateRunningDates() {
            this.runningDays = Math.floor(((new Date()) - new Date(this.battery_cycle_date)) / (1000 * 60 * 60 * 24));
            if (this.limitBatteryDate != "") {
                console.log("limitBatteryDate", this.limitBatteryDate, this.battery_cycle_date);
                // calculate the runningDays from the highest date to the limitbatteryDate
                this.runningDays = Math.floor((new Date(this.limitBatteryDate) - new Date(this.battery_cycle_date)) / (1000 * 60 * 60 * 24));
            }
            // calculate the extra days, the calculation should return the difference in days from the endDate to the limitBatteryDate
            const supposedTime = Math.floor((new Date(this.endDate) - new Date(this.battery_cycle_date)) / (1000 * 60 * 60 * 24));
            this.extraDays = this.runningDays-supposedTime;
            console.log("extraDays", this.extraDays);
            if (this.extraDays > 0) {
                this.extraLabel = "Extra " + this.extraDays + " days";
            }
        },
        downloadData() {
            this.btnUpdate = true;
            this.markers = [];
            this.markersLoaded = false;
            // if (this.dataset.length != 0) {
            //     this.markers = this.dataset;
            // }
            console.log("batteries=>", this.$store.state.devices.battery_info);
            if (
                this.$store.state.devices.battery_info.length == 0 &&
                this.device != null
            ) {
                this.$store
                    .dispatch(GET_BATTERIES)
                    // go to which page after successfully login
                    .then((data) => {
                        // download devices list
                        // display only the batteries for this device
                        this.batteries_available = data.filter((item) => {
                            // console.log(item.device_type,this.device.version);
                            return item.device_type == this.device.version;
                        });
                        this.updateBatteryInfo();
                    });
            } else if (this.device != null) {
                this.updateBatteryInfo();
            }
        },
        isSelected(item) {
            return this.selectedMarker === item;
        },
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
    },
    watch: {
        device: function (oldval, newval) {
            oldval, newval;
            // this is commented because this particular component do not require downloading data here
            this.downloadData();
        },
    },
};
</script>

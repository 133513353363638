<template>
    <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                <h6 class="mb-0">Device raw data</h6>
                <b-button variant="success" v-on:click="downloadData" :disabled="btnUpdate == true">
                    <b-spinner small v-show="btnUpdate == true"></b-spinner>
                    Update
                </b-button>
            </div>
        </template>

        <template v-if="statusLoaded == null">
            <div class="d-flex justify-content-center mb-3">
                <b-spinner variant="primary"> </b-spinner>
            </div>
        </template>
        <template v-else>
            <div>
                <b-button v-b-toggle.collapse-1 variant="primary">Expand raw data</b-button>
                <b-collapse id="collapse-1" class="mt-2">
                    <b-card>
                        <div>
                            <b-table striped hover :items="markers" @row-clicked="rowClicked"
                                :tbody-tr-class="rowClassCallback" :fields="tableFields">
                                <template v-slot:cell(created_at)="data">
                                    {{ formatDate(data.item.created_at) }}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </b-collapse>
            </div>

        </template>
    </b-card>
</template>

<script>
// import { GET_FINDMY_SOIL_SENSOR } from "@/core/services/store/devices.module";
import { mapGetters } from "vuex";
export default {
    name: "suitch-raw-details-widget",
    components: {},
    props: {
        device: null,
        dataset: [],
        showName: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type == "setMessage") {
                // verify if the type of message is for this widget
                if (
                    this.device != null &&
                    state.realtime.last_message.type == "location" &&
                    state.realtime.last_message.token == this.device.token
                ) {
                    // call again the action to get the status
                    this.downloadData();
                }
            }
        });
        this.downloadData();
    },
    data() {
        return {
            activeGeofence: null,
            selectedGeofence: null,
            geofences: [],
            current_device: { connection_type: -1 },
            statusLoaded: null,
            center: { lat: 25.686613, lng: -100.316116 },
            markers: [],
            polygonsTableFields: [
                { key: 'color', label: 'Color' },
                { key: 'count', label: 'Marker Count' },
            ],
            show: "day",
            markersLoaded: false,
            tableFields: [
                { key: "value", label: "Value" },
                { key: "created_at", label: "Updated At", formatter: "formatDate" },
            ],
            btnUpdate: false,
        };
    },
    methods: {
        rowClassCallback(item, type) {
            if (type === "row") {
                return this.isSelected(item) ? "map-selected-row" : "";
            }
            return "";
        },
        rowClicked(clickedItem) {
            if (this.selectedMarker === clickedItem) {
                this.selectedMarker = null; // Unselect if it's already selected
            } else {
                this.selectedMarker = clickedItem;
            }
        },
        formatDate(dateString) {
            const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            let date;
            try {
                date = new Date(dateString);
                if (isNaN(date)) {
                    throw new Error('Invalid date');
                }
                return new Intl.DateTimeFormat(undefined, options).format(date);
            } catch (err) {
                console.error('Error formatting date:', err);
                return null;
            }
        },

        downloadData() {
            this.btnUpdate = true;
            this.markers = [];
            this.markersLoaded = false;
            if (this.dataset.length != 0) {
                this.markers = this.dataset;
            }
            this.statusLoaded = {};
            this.btnUpdate = false;
        },
        isSelected(item) {
            return this.selectedMarker === item;
        },
    },
    computed: {
        ...mapGetters(["layoutConfig"]),
    },
    watch: {
        device: function (oldval, newval) {
            oldval, newval;
            // this is commented because this particular component do not require downloading data here
            //this.downloadData();
        },
        dataset: function (oldval, newval) {
            oldval, newval;
            this.downloadData();
        },
    },
};
</script>

<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true && device!=null">Device Status ({{device.object}})</h6>
      <h6 class="mb-0" v-else>Device Status</h6>
    </template>
    
    <template v-if="statusLoaded == null" > 
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"
        >
        </b-spinner>
      </div>
    </template>
    <template v-else>
    <b-alert show variant="success" v-if="current_device.connection_type == 1"
      >ONLINE since {{ current_device.created_at | formatDate }}</b-alert
    >
    <b-alert show variant="warning" v-if="current_device.connection_type == 2"
      >WEAK SIGNAL since {{ current_device.created_at | formatDate }}</b-alert
    >
    <b-alert show variant="danger" v-if="current_device.connection_type == 0"
      >OFFLINE since {{ current_device.created_at | formatDate }}</b-alert
    >
    <b-alert
      show
      variant="secondary"
      v-if="current_device.connection_type == -1"
      >Never connected or disconnected</b-alert
    >
    </template>
  </b-card>
</template>

<script>
import { STORE_TRACKER_LOCATIONS,
  STORE_SOIL_SENSOR_VALUES } from "@/core/services/store/devices.module";
export default {
  name: "suitch-tracker-status-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false
    },
    findMyType: {
      type: String,
      default: "tracker"
    },
  },
  mounted() {
    this.$store
      .subscribe((mutation,state)=>{
      // console.log("mutation-->",mutation.type, state)

      if(this.findMyType == "tracker" && mutation.type === STORE_TRACKER_LOCATIONS){
        state;
        console.log("TRACKER_1",this.$store.state.devices.trackerLocations);
        if(this.$store.state.devices.trackerLocations.length > 0){
          const lastMsg = this.$store.state.devices.trackerLocations[0];
          this.isOlderThanThreeHours(lastMsg.created_at);
        }
        this.statusLoaded = true;
      } else {
        console.log("SOIL_1",this.$store.state.devices.soilSensorValues);
        if(this.findMyType == "soil" && mutation.type === STORE_SOIL_SENSOR_VALUES){
        state;
        const lastMsg = this.$store.state.devices.soilSensorValues[0];
        console.log(lastMsg);
        this.isOlderThanThreeHours(lastMsg.created_at);
        this.statusLoaded = true;
      }
      }
    })
  },
  data() {
    return {
      current_device: { connection_type: -1 },
      statusLoaded: null,
    };
  },
  methods: {
    isOlderThanThreeHours(dateString) {
    // Parse the date string and convert it to local time
    const date = new Date(dateString);
    const localDate = new Date(date.toLocaleString());

    // Get the current date and time
    const now = new Date();

    // Calculate the difference in milliseconds
    const difference = now.getTime() - localDate.getTime();

    // Convert the difference to hours
    const differenceInHours = difference / (1000 * 60 * 60);

    // Return true if the difference is more than 3 hours, otherwise return false
    if(differenceInHours > 6){
      this.current_device.connection_type = 0;
      this.current_device.created_at = dateString;
    } else if(differenceInHours > 3){
      this.current_device.connection_type = 2;
      this.current_device.created_at = dateString;
    } else {
      this.current_device.connection_type = 1;
      this.current_device.created_at = dateString;
    }
    console.log(localDate);
    console.log(this.current_device);
    console.log("hours,",differenceInHours);
  }
  },
  watch: {
    
  },
};
</script>
